import { ChangeDetectorRef, Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { UtilService } from 'src/app/service/util.service';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  languageOptions: any[];

  hoveredSection: string = '';

  @ViewChildren(SidebarMenuComponent) objAssetLang!: QueryList<SidebarMenuComponent>;

  sectionIsActiveAbout: boolean = false;
  sectionIsActiveBusiness: boolean = false;
  sectionIsActiveSustain: boolean = false;
  sectionIsActiveEvent: boolean = false;
  sectionIsActiveDevelop: boolean = false;
  sectionIsActiveTool: boolean = false;
  sectionIsActiveInvestor: boolean = false;
  sectionIsActivePrivilege: boolean = false;
  sectionIsActiveCareer: boolean = false;
  sectionIsActiveContact: boolean = false;

  mobileMenu: boolean = false;

  openSideMenu(){
    this.mobileMenu = true;
  }

  closeSideMenu(event:boolean) {
    this.mobileMenu = false;
  } 

  onHover(section: string) {
    // console.log(`Hovered over ${section}`);
    this.hoverService.sendHoverValue(section);
  }

  ngOnInit() {
    this.hoverService.hover$.subscribe((value) => {
      this.hoveredSection = value;
    });
    
    this.checkRoute(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute(event.urlAfterRedirects);
      }
    });
  }

  private checkRoute(url: string) {
    this.sectionIsActiveAbout = url.startsWith('/aboutus/');
    this.sectionIsActiveBusiness = url.startsWith('/business/');
    this.sectionIsActiveSustain = url.startsWith('/sustainability/');
    this.sectionIsActiveEvent = url.startsWith('/news-events/');
    this.sectionIsActiveDevelop = url.startsWith('/develop/');
    this.sectionIsActiveTool = url.startsWith('/tools/');
    this.sectionIsActiveInvestor = url.startsWith('/investor/');
    this.sectionIsActiveCareer = url.startsWith('/career/');
    this.sectionIsActiveContact = url.startsWith('/contact/');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hoverService.sendHoverValue('');
    this.cdr.detectChanges();
  }

  selectLang: string = 'TH';
  constructor(private translate: TranslateService, private hoverService: UtilService, private cdr: ChangeDetectorRef, private router: Router,
  ) {
    this.languageOptions = [
      { label: 'TH', value: 'TH' },
      { label: 'EN', value: 'EN' },
    ];
    translate.addLangs(['EN', 'TH']);
    translate.setDefaultLang('EN');
    const browserLang = translate.getBrowserLang();
    let lang = browserLang!.match(/EN TH/) ? browserLang : 'TH';
    this.changeLang!(lang!);
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.selectLang = lang;
    this.hoverService.setSelectedLang(lang);
  }

  isScrolled: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the scroll position is greater than 100px
    this.isScrolled = scrollPosition >= 100;
  }

  section: string = 'banner';

  navTo(locat: string): void {
    this.router.navigate([locat]);
    this.hoverService.sendHoverValue('');
  }
}

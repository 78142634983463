import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/sa-main/sa-main.module').then(
            (m) => m.SaMainModule
          ),
      },
      {
        path: 'aboutus',
        loadChildren: () =>
          import('./modules/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
      {
        path: 'sustainability',
        loadChildren: () =>
          import('./modules/sustain/sustain.module').then(
            (m) => m.SustainModule
          ),
      },
      {
        path: 'business',
        loadChildren: () =>
          import('./modules/business/business.module').then(
            (m) => m.BusinessModule
          ),
      },
      {
        path: 'news-events',
        loadChildren: () =>
          import('./modules/news-event/news-event.module').then(
            (m) => m.NewsEventModule
          ),
      },
      {
        path: 'tools',
        loadChildren: () =>
          import('./modules/tools/tools.module').then(
            (m) => m.ToolsModule
          ),
      },
      {
        path: 'career',
        loadChildren: () =>
          import('./modules/career/career.module').then(
            (m) => m.CareerModule
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./modules/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

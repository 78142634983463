import { Injectable } from '@angular/core';
import { menuProp } from '../model/util-model';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router) { }


  private selectedLang!: string;

  private selectedLangSubject = new BehaviorSubject<string>('TH');
  selectedLang$ = this.selectedLangSubject.asObservable();
  
  setSelectedLang(lang: string): void {
    this.selectedLangSubject.next(lang);
  }
  getSelectedLang(): string {
    return this.selectedLang;
  }
  
  private hoverSource = new BehaviorSubject<string>('');
  hover$ = this.hoverSource.asObservable();

  sendHoverValue(value: string) {
    this.hoverSource.next(value);
  }

  thai!: menuProp[];
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {

  selectedLang: string = '';

  constructor( private router: Router, private hoverService: UtilService, private cdr: ChangeDetectorRef
  ) {
    this.hoverService.selectedLang$.subscribe((lang) => {
      this.selectedLang = lang;
    });
   }

  @Input() hoveredSection: string = '';

  section: string = 'banner';

  @Output() onHideSidebar = new EventEmitter<boolean>();

  navTo(locat: string): void {
    this.router.navigate([locat]);
    this.onHideSidebar.emit(false);
    this.isSectionActive(locat)
  }

  isSectionActive(section: string): boolean {
    return this.hoveredSection === section;
  }

  openAboutMenu: boolean = false;

  aboutMenu() {
    this.openAboutMenu = !this.openAboutMenu;
  }

  openBusinessMenu: boolean = false;

  businessMenu() {
    this.openBusinessMenu = !this.openBusinessMenu;
  }

  openEventMenu: boolean = false;

  eventMenu() {
    this.openEventMenu = !this.openEventMenu;
  }

  openDevelopMenu: boolean = false;

  developMenu() {
    this.openDevelopMenu = !this.openDevelopMenu;
  }

  openToolMenu: boolean = false;

  toolMenu() {
    this.openToolMenu = !this.openToolMenu;
  }

  openCareerMenu: boolean = false;

  careerMenu() {
    this.openCareerMenu = !this.openCareerMenu;
  }

  openContactMenu: boolean = false;

  contactMenu() {
    this.openContactMenu = !this.openContactMenu;
  }

  ngOnInit() {
    this.hoverService.hover$.subscribe((value) => {
      this.hoveredSection = value;
    });
    
    this.checkRoute(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute(event.urlAfterRedirects);
      }
    });
  }

  sectionIsActiveAbout: boolean = false;
  sectionIsActiveBusiness: boolean = false;
  sectionIsActiveEvent: boolean = false;
  sectionIsActiveDevelop: boolean = false;
  sectionIsActiveTool: boolean = false;
  sectionIsActiveInvestor: boolean = false;
  sectionIsActiveCareer: boolean = false;
  sectionIsActiveContact: boolean = false;

  private checkRoute(url: string) {
    this.sectionIsActiveAbout = url.startsWith('/aboutus/');
    this.sectionIsActiveBusiness = url.startsWith('/business/');
    this.sectionIsActiveEvent = url.startsWith('/news-events/');
    this.sectionIsActiveDevelop = url.startsWith('/develop/');
    this.sectionIsActiveTool = url.startsWith('/tools/');
    this.sectionIsActiveInvestor = url.startsWith('/investor/');
    this.sectionIsActiveCareer = url.startsWith('/career/');
    this.sectionIsActiveContact = url.startsWith('/contact/');
  }
  activeRoute: string = '';

  // ngOnInit() {

  //   this.hoverService.hover$.subscribe((value) => {
  //     this.hoveredSection = value;
  //   });

  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       const path = event.urlAfterRedirects.split('?')[0];
  //       this.activeRoute = path;
  //       this.cdr.detectChanges();
  //     }
  //   });
  // }
}

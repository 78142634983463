<div class="flex flex-column p-3 sm:p-4 bg-white fixed z-5 top-0 left-0 w-screen shadow-1">
  <div *ngIf="!isScrolled" class="flex flex-row justify-content-between xl:justify-content-center xl:mb-3">
    <div class="hidden xl:flex align-items-center"></div>
    <div class="flex align-items-center justify-content-center">
      <img (click)="navTo('/')" class="w-10rem sm:w-18rem flex align-items-center cursor-pointer"
        src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/logos-icons/new-logo-v3.png" alt="" />
    </div>
    <div class="flex align-items-center sm:absolute right-0 sm:pt-2 sm:pr-4 xl:pr-5">
      <p-dropdown styleClass="h-3rem" [options]="languageOptions" [(ngModel)]="selectLang"
        (ngModelChange)="changeLang($event)" class="align-items-center inline-flex"></p-dropdown>
      <div class="w-auto xl:hidden ml-2">
        <p-button styleClass="h-3rem" class="p-2 min-[1367px]:hidden flex align-items-center" icon="pi pi-align-justify"
          (click)="openSideMenu()"></p-button>
      </div>
    </div>
  </div>
  <div class="flex flex-row xl:mb-1" [ngClass]="{
      'justify-content-center': isScrolled === false,
      'justify-content-between': isScrolled === true
    }">
    <div [ngClass]="{
        hidden: isScrolled === false,
        flex: isScrolled === true
      }" class="align-items-center">
      <img (click)="navTo('/')" class="w-10rem sm:w-12rem flex align-items-center cursor-pointer"
        src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/logos-icons/new-logo-v3.png" alt="" />
    </div>
    <div [ngClass]="{
      '-mt-1': isScrolled === true,
      'pt-2': selectLang === 'EN',
      '': selectLang === 'TH'}"
      class="hidden xl:flex xl:flex-row justify-content-center gap-3 align-items-center text-lg sm:text-2xl font-bold">
      <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveAbout,
          'text-color': !sectionIsActiveAbout,
        }" (mouseenter)="onHover('aboutus')">
        {{ "header.aboutus" | translate }}
      </div>
      <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveBusiness,
          'text-color': !sectionIsActiveBusiness,
        }" (mouseenter)="onHover('business')">
        {{ "header.business" | translate }}
      </div>
      <div class="cursor-pointer hover:text-primary" (click)="navTo('/sustainability/environment')" f [ngClass]="{
        'fontS': selectLang === 'EN',
        'text-primary': sectionIsActiveSustain,
          'text-color': !sectionIsActiveSustain,
      }">
        {{ "header.sustain" | translate }}
      </div>
      <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveEvent,
          'text-color': !sectionIsActiveEvent,
        }" (mouseenter)="onHover('event')">
        {{ "header.event" | translate }}
      </div>
      <!-- <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveDevelop,
          'text-color': !sectionIsActiveDevelop,
        }" (mouseenter)="onHover('develop')">
        {{ "header.develop" | translate }}
      </div> -->
      <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveTool,
          'text-color': !sectionIsActiveTool
        }" (mouseenter)="onHover('tool')">
        {{ "header.tool" | translate }}
      </div>
      <!-- <div class="cursor-pointer hover:text-primary" [ngClass]="{
          'fontS': selectLang === 'EN',
          'text-primary': sectionIsActiveInvestor,
          'text-color': !sectionIsActiveInvestor
        }" (mouseenter)="onHover('investor')">
        {{ "header.investor" | translate }}
      </div> -->
      <a target="_blank" rel="noopener noreferrer" href="https://investor.siameseasset.co.th/th/home"
        class="no-underline hover:text-primary" [ngClass]="{
        'fontS': selectLang === 'EN',
        'text-primary': sectionIsActiveInvestor,
        'text-color': !sectionIsActiveInvestor
      }">
        {{ "header.investor" | translate }}
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.siameseprivilege.com/"
        class="no-underline hover:text-primary" [ngClass]="{
        'fontS': selectLang === 'EN',
        'text-primary': sectionIsActivePrivilege,
        'text-color': !sectionIsActivePrivilege
      }">
        {{ "header.privilege" | translate }}
      </a>
      <!-- <div class="cursor-pointer hover:text-primary" [ngClass]="{
        'fontS': selectLang === 'EN',
        'text-primary': sectionIsActiveCareer,
        'text-color': !sectionIsActiveCareer
      }" (mouseenter)="onHover('career')">
        {{ "header.career" | translate }}
      </div> -->
      <div class="cursor-pointer hover:text-primary" [ngClass]="{
        'fontS': selectLang === 'EN',
        'text-primary': sectionIsActiveContact,
        'text-color': !sectionIsActiveContact
      }" (mouseenter)="onHover('contact')">
        {{ "header.contact" | translate }}
      </div>
    </div>
    <div class="align-items-center" [ngClass]="{
      flex: isScrolled === true,
      hidden: isScrolled === false
      }">
      <p-dropdown styleClass="h-3rem" [options]="languageOptions" [(ngModel)]="selectLang"
        (ngModelChange)="changeLang($event)" class="align-items-center inline-flex"></p-dropdown>
      <div class="w-auto xl:hidden ml-2">
        <p-button styleClass="h-3rem" class="p-2 min-[1367px]:hidden flex align-items-center" icon="pi pi-align-justify"
          (click)="openSideMenu()"></p-button>
      </div>
    </div>
  </div>

  <app-menu-header class="showMenu" *ngIf="hoveredSection !== ''"></app-menu-header>
</div>

<p-sidebar [(visible)]="mobileMenu" position="right" appendTo="body" styleClass="w-15rem">
  <app-sidebar-menu (onHideSidebar)="closeSideMenu($event)"></app-sidebar-menu>
</p-sidebar>
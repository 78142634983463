import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent {

  @Input() hoveredSection: string = '';

  @Input() section: string = '';

  selectedLang: string = '';

  constructor(private hoverService: UtilService, private cdr: ChangeDetectorRef, private router: Router) {
    this.hoverService.selectedLang$.subscribe((lang) => {
      this.selectedLang = lang;
    });
  }

  isSectionActive(section: string): boolean {
    return this.hoveredSection === section;
  }

  navTo(locat: string): void {
    this.router.navigate([locat]);
    this.hoverService.sendHoverValue('');
    this.isSectionActive(locat)
  }

  activeRoute: string = '';

  ngOnInit() {

    this.hoverService.hover$.subscribe((value) => {
      this.hoveredSection = value;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.urlAfterRedirects.split('?')[0];
        this.activeRoute = path;
        this.cdr.detectChanges();
      }
    });
  }
}

<div class="pt-3 hidden lg:flex flex-column justify-content-center">
    <div class="border-bottom-1 border-primary pt-2"></div>
    <div class="pt-5">
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
                'flex': hoveredSection === 'aboutus',
                'hidden': hoveredSection !== 'aboutus'
            }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-1.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline font-bold text-700"
                            (click)="navTo('/aboutus/sainfo')" routerLink="/aboutus/sainfo"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus1" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline font-bold text-700"
                            (click)="navTo('/aboutus/vandm')" routerLink="/aboutus/vandm"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus2" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <!-- <div class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline text-700"
                            (click)="navTo('/aboutus/journey')" routerLink="/aboutus/journey"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus3" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                    <div class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline font-bold text-700"
                            (click)="navTo('/aboutus/awards')" routerLink="/aboutus/awards"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus4" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
                <div class="flex flex-column w-5">
                    <div class="py-2 flex justify-content-between border-bottom-1 font-bold border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline text-700"
                            (click)="navTo('/aboutus/letter')" routerLink="/aboutus/letter"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus5" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div class="py-2 flex justify-content-between border-bottom-1 font-bold border-300 mb-2">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="cursor-pointer hover:text-primary no-underline text-700"
                            (click)="navTo('/aboutus/board')" routerLink="/aboutus/board"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}">
                            {{ "menuHeader.aboutus6" | translate }}
                        </a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'business',
            'hidden': hoveredSection !== 'business'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-2.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <!-- <div class="py-2 flex justify-content-between mb-2 align-items-center cursor-pointer">
                        <a [ngClass]="{ 'flex align-items-end text-xs': selectedLang === 'EN',
                            'text-base': selectedLang !== 'EN'}" (click)="navTo('/business/main-real-estate')"
                            routerLink="/business/main-real-estate" routerLinkActive="text-primary"
                            [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                    <!-- <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/main-real-estate')" routerLink="/business/main-real-estate"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/branded-residence')" routerLink="/business/branded-residence"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property1" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/condominium')" routerLink="/business/condominium"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property2" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/house')" routerLink="/business/house"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property3" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/townhome')" routerLink="/business/townhome"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.property4" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
                <div class="flex flex-column w-5">
                    <!-- <div class="py-2 flex justify-content-between mb-2 align-items-center cursor-pointer">
                        <a [ngClass]="{ 'flex align-items-end text-xs': selectedLang === 'EN',
                        'text-base': selectedLang !== 'EN'}" (click)="navTo('/business/main-others')"
                            routerLink="/business/main-others" routerLinkActive="text-primary"
                            [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                    <!-- <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/main-others')" routerLink="/business/main-others"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/hotel')" routerLink="/business/hotel"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other1" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/food-beverage')" routerLink="/business/food-beverage"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other2" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/health-wellness')" routerLink="/business/health-wellness"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other3" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/technology')" routerLink="/business/technology"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other4" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/finance-investment')" routerLink="/business/finance-investment"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other5" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <!-- <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/business/leasing')" routerLink="/business/leasing"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "footer.other6" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'event',
            'hidden': hoveredSection !== 'event'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-3.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/news-events/all-news&events')" routerLink="/news-events/all-news&events"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "news.all" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/news-events/all-news')" routerLink="/news-events/all-news"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.new1" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/news-events/all-events')" routerLink="/news-events/all-events"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.new2" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/news-events/all-promotions')" routerLink="/news-events/all-promotions"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.new3" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <!-- <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{
                            'text-primary': hoveredSection === 'aboutus/sainfo',
                            'text-color': hoveredSection !== 'aboutus/sainfo'
                            }">{{ "header.blog" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'develop',
            'hidden': hoveredSection !== 'develop'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-4.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">
                            {{ "menuHeader.develop1" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.develop2" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.develop3" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.develop4" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.develop5" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.develop6" | translate }}</span>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'tool',
            'hidden': hoveredSection !== 'tool'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-5.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/tools/installment-tool')" routerLink="/tools/installment-tool"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.tool1" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a href="https://app.siameseasset.co.th/onlinepayment/" target="_blank"
                            rel="noopener noreferrer" [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.tool2" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a href="https://app.siameseasset.co.th/sabox/" target="_blank" rel="noopener noreferrer"
                            [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.tool3" |
                            translate }}</a>
                        <!-- <span [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" class="text-color">{{
                            "menuHeader.tool3" | translate }}</span> -->
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'investor',
            'hidden': hoveredSection !== 'investor'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-7.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" target="_blank"
                            rel="noopener noreferrer" href="https://investor.siameseasset.co.th/th/home"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "header.investor" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'privilege',
            'hidden': hoveredSection !== 'privilege'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-7.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-xl">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}" target="_blank"
                            rel="noopener noreferrer" href="https://www.siameseprivilege.com/"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "header.privilege" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'career',
            'hidden': hoveredSection !== 'career'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-8.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row gap-8 text-sm">
                <div class="flex flex-column w-5">
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/career/sa-career')" routerLink="/career/sa-career"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.career1" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                    <div
                        class="py-2 flex justify-content-between border-bottom-1 border-300 mb-2 cursor-pointer hover:text-primary">
                        <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                            (click)="navTo('/career/sa-trainee')" routerLink="/career/sa-trainee"
                            routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                            class="font-bold text-700 hover:text-primary no-underline">{{
                            "menuHeader.career2" |
                            translate }}</a>
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="flex-row gap-6 justify-content-center" [ngClass]="{
            'flex': hoveredSection === 'contact',
            'hidden': hoveredSection !== 'contact'
          }">
            <div class="w-3"><img class="w-full"
                    src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/dropdown-images/DropDown_Pic-9.jpg" alt="">
            </div>
            <div class="w-5 flex flex-row text-xl">
                <div class="flex flex-column w-full">
                    <div class="flex flex-column w-5">
                        <div
                            class="py-2 border-bottom-1 flex justify-content-between border-300 mb-2 cursor-pointer hover:text-primary">
                            <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                                (click)="navTo('/contact/all-contact')" routerLink="/contact/all-contact"
                                routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                                class="font-bold text-700 hover:text-primary no-underline">{{
                                "menuHeader.contact1" |
                                translate }}</a>
                            <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                        </div>
                        <div
                            class="py-2 border-bottom-1 flex justify-content-between border-300 mb-2 cursor-pointer hover:text-primary">
                            <a [ngClass]="{ 'fontS flex align-items-end': selectedLang === 'EN'}"
                                (click)="navTo('/contact/report-contact')" routerLink="/contact/report-contact"
                                routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                                class="font-bold text-700 hover:text-primary no-underline text-left">{{
                                "menuHeader.contact2" |
                                translate }}</a>
                            <i class="pi pi-arrow-up-right text-primary flex align-items-center" style="font-size: 1rem;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
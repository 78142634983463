import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  active: boolean = true;
  selectedLang: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private langService: UtilService) {
    this.langService.selectedLang$.subscribe((lang) => {
      this.selectedLang = lang;
    });
   }

  ngOnInit() {
    this.setActiveState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveState(event.urlAfterRedirects);
      }
    });
  }

  private setActiveState(url: string) {
    const path = url.split('?')[0]; 
    this.active = path === '/';
  }

  navTo(locat: string): void {
    this.router.navigate([locat]);
    this.langService.sendHoverValue('');
  }
}
